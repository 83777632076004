@import "scss/vars";
@import "scss/mixins";

.root {
  position: fixed;
  right: 1rem;
  bottom: 1rem;
  z-index: 99;

  @include shadow(high);

  &.shifted {
    bottom: calc(#{$mobilePlayerBarHeight} + 1rem);
  }

  &.showCloseIcon {
    box-shadow: none;
  }
}
