@import "scss/mixins";

.root {
  display: flex;
  align-items: center;
  color: var(--color-emphasis-low);

  .icon {
    width: 48px;
    height: 24px;
  }

  .disk {
    font-weight: bold;
  }

  @include canHover {
    padding: 0.5rem;
  }
}
