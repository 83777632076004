@import "scss/mixins";

.root {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  padding: 0.5rem 1rem;
  margin-bottom: 2rem;
  color: #121212;
  border-radius: 3px;

  &.error {
    background-color: var(--color-danger);
  }

  &.success {
    background-color: var(--color-success);
  }

  @include shadow(low);
}
