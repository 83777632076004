@import "scss/mixins";

.root {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  border: 0;
  background-color: var(--color-interaction-background);
  color: var(--color-emphasis-medium);
  width: 48px;
  height: 48px;
  border-radius: 100%;
  transition: background-color 0.15s;
  outline: 0;
  user-select: none;

  &:disabled {
    cursor: auto;
    opacity: 0.5;
  }

  &:focus {
    outline: 1px solid var(--color-interaction-focus);
  }

  &.withBorder {
    border: 1px solid rgba(#000, 0.2);
  }

  &.primary {
    color: #fff;
    background-color: var(--color-primary);

    @include shadow(low);
  }

  @include canHover {
    &:hover:not(:disabled) {
      cursor: pointer;

      &:not(.primary) {
        background-color: rgba(#000, 0.1);
      }
    }
  }
}
