.root {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.5rem 0;

  > h2 {
    letter-spacing: 1.5px;
    font-weight: normal;
    text-transform: uppercase;
    font-size: 1em;
  }
}
