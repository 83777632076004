.root {
  display: flex;
  align-items: center;
  width: 100%;

  .bufferedOverlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(#eee, 0.3);
    transition: width 0.25s;
  }
}
