.root {
  --padding: 1rem;
  --radius: 0.5rem;
  --duration: 150ms;
  --height: var(--tap-target);

  position: relative;

  > label {
    position: absolute;
    left: calc(var(--padding) - 0.25rem);
    top: 0.35rem;
    color: var(--color-emphasis-medium);
    transition: color var(--duration), opacity var(--duration), transform var(--duration);
    padding: 0 0.25rem;
    font-size: 0.75em;
    transform: translateY(0.5rem);
    opacity: 0;
    z-index: 1;
    pointer-events: none;
  }

  > input {
    position: relative;
    background-color: var(--color-interaction-background);
    border: 1px solid var(--color-interaction-border);
    border-radius: var(--radius);
    padding: 0 var(--padding);
    height: var(--height);
    display: block;
    outline: 0;
    width: 100%;
    transform: translateY(0);
    color: var(--color-emphasis-high);
    transition: border-color 0.15s, padding-top 0.15s;
    font: inherit;

    &:focus {
      border-color: var(--color-interaction-focus);
    }

    &::placeholder {
      color: var(--color-emphasis-low);
      opacity: 1;
    }
  }

  &.hasIcon {
    > input {
      padding-left: calc(var(--padding) + 2rem);
    }
  }

  &.hasLabel.hasValue {
    > input {
      padding-top: 0.8rem;
    }

    > label {
      color: var(--color-interaction-focus);
      transform: translate(0);
      opacity: 1;
    }
  }

  .icon {
    display: flex;
    align-items: center;
    position: absolute;
    left: var(--padding);
    top: 0;
    height: var(--height);
    z-index: 1;
  }

  .errorContainer {
    display: block;
    width: 100%;
    color: #fff;
    margin-top: calc(var(--radius) * -1);
    padding: calc(var(--radius) + 0.1em) var(--padding) 0.2em;
    border-radius: 0 0 var(--radius) var(--radius);
    background-color: var(--color-danger);
    transform: scaleY(0);
    transform-origin: top;
    transition: transform var(--duration);
  }

  .error {
    display: block;
    width: 100%;
    font-size: 0.75em;
    min-height: 1em;
    line-height: 1;
  }

  &.hasError {
    > input:not(:focus) {
      border-color: var(--color-danger);
    }

    .errorContainer {
      transform: scaleY(1);
    }
  }
}
