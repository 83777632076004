@import "scss/mixins";

.root {
  letter-spacing: 3px;
  font-size: 1.5em;
  margin: 1rem 0;
  text-transform: uppercase;
  font-weight: normal;

  @include desktop {
    font-size: 2em;
    margin: 2.5rem 0 1.5rem 0;
  }
}
