@import "scss/vars";
@import "scss/mixins";

$padding: 0.75rem;

.root {
  background-color: var(--color-primary);
  color: #fff;
  padding: $padding;

  --gap: 1rem;

  @media (min-width: 950px) {
    --gap: 2rem;
  }

  .layout {
    height: 100%;
    display: grid;
    grid-template-columns: calc(#{$desktopSidebarWidth} - #{$padding * 2}) calc(1rem + var(--gap)) 1fr 1fr var(--gap) min-content;
    grid-template-rows: 1fr 22px;
    grid-template-areas:
      "current . main-controls side-controls . queue"
      "current . progress progress . queue";
  }

  .sideControls,
  .mainControls,
  .progress {
    display: flex;
    align-items: center;
  }

  .current {
    display: flex;
    align-items: center;
    grid-area: current;
    min-height: 0;
    min-width: 0;
  }

  .mainControls {
    grid-area: main-controls;
    gap: 2rem;

    .durations .slash {
      margin: 0 0.25rem;
    }
  }

  .sideControls {
    grid-area: side-controls;
    justify-content: flex-end;
    gap: 1rem;
  }

  .progress {
    grid-area: progress;
    justify-content: center;
  }

  .queueButton {
    grid-area: queue;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
  }
}
