@import "scss/vars";

.root {
  .menu {
    visibility: hidden;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(#000, 0.75);
    z-index: 98;
    color: #fff;
    text-align: right;
    padding: 1rem 1rem calc(1rem + 48px + 1rem) 1rem;
    opacity: 0;
    transition: opacity 0.15s, visibility 0.15s 0s;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;

    &.shifted {
      padding: 1rem 1rem calc(#{$mobilePlayerBarHeight} + 1rem + 48px + 1rem) 1rem;
    }

    .navItem {
      display: inline-flex;
      align-items: center;
      color: inherit;
      text-decoration: none;
      padding: 0.5rem 0;
      opacity: 0;
      transform: translateY(1rem);
      transition: opacity 0.05s, transform 0.05s;

      .iconWrapper {
        width: 48px;
        height: 48px;
        display: inline-grid;
        place-items: center;
      }
    }

    &.isVisible {
      visibility: visible;
      opacity: 1;

      .navItem {
        opacity: 1;
        transform: translateY(0);
      }
    }
  }
}
