@import "scss/mixins";

.root {
  position: fixed;
  top: 0;
  right: 0;
  padding: 0.5rem;

  .notification {
    position: relative;
    padding: 0.75rem 3rem 0.75rem 1.5rem;
    background-color: var(--color-elevation-high);
    margin-bottom: 0.5rem;
    font-size: 0.9em;
    cursor: pointer;

    @include shadow(high);

    .title {
      color: var(--color-emphasis-medium);
      margin: 0;
    }

    .message {
      margin: 0.5rem 0 0 0;
    }

    .close {
      position: absolute;
      top: 0.75rem;
      right: 0.75rem;
      color: var(--color-emphasis-low);
      width: 16px;
      height: 16px;
    }

    &.error {
      background-color: var(--color-danger);
      color: #fff;

      .title,
      .close {
        color: #fff;
      }
    }
  }
}
