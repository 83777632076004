@import "scss/mixins";

.root {
  padding: 5%;

  .form {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    max-width: 400px;
    margin: 0 auto;
    background-color: var(--color-elevation-medium);
    border-radius: 6px;
    padding: 2rem;

    @include shadow(medium);

    .actions {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 1rem;
      justify-content: space-between;

      > * {
        width: 100%;
      }
    }
  }
}
