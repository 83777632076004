.root {
  position: relative;
  margin: 0.5rem 0 1rem 0;

  label {
    align-items: center;
    display: inline-flex;
    gap: 0.35rem;
    color: var(--color-emphasis-medium);
    cursor: pointer;

    input {
      position: absolute;
      transform: translate(-10000px);
    }
  }

  .icon {
    position: relative;
    color: var(--color-primary);
  }

  &.focus .icon {
    outline: 1px dashed var(--color-interaction-focus);
  }
}
