@import "scss/mixins";

.navEntry {
  display: flex;
  align-items: center;
  padding: 1rem 1.5rem;
  color: #fff;
  transition: color 0.2s, background-color 0.2s;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    background-color: rgba(#fff, 0.05);
  }

  &.active {
    position: relative;

    @include active-dot();
  }

  .icon {
    display: flex;
    align-items: center;
    margin-right: 1rem;
  }

  .label {
    @include text-ellipsis();
  }
}
