@import "scss/mixins";

.wrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(#000, 0.6);
  display: grid;
  justify-content: stretch;
  align-content: center;
  padding: 1rem;
  z-index: 99;

  .modal {
    background-color: var(--color-elevation-medium);
    max-width: 600px;
    width: 100%;
    margin: auto;
    border-radius: 4px;

    @include shadow(high);

    &:focus {
      outline: 1px dashed rgba(#fff, 0.8);
      outline-offset: 2px;
    }
  }

  .header {
    display: flex;
    align-items: center;
    padding-left: 1.5rem;
    background-color: var(--color-primary);
    color: #fff;

    > h1 {
      letter-spacing: 1.5px;
      font-weight: normal;
      text-transform: uppercase;
      font-size: 1em;
    }

    > button {
      margin-left: auto;
    }
  }

  .content {
    padding: 2rem 1.5rem;
    overflow-y: auto;
  }
}
