.root {
  color: currentColor;
  white-space: nowrap;
  text-decoration: none;

  &.underlined,
  &:hover {
    text-decoration: underline;
  }
}
