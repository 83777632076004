@import "scss/vars";
@import "scss/mixins";

.root {
  background-color: var(--color-elevation-low);
  overflow: hidden;

  .inner {
    padding: 1rem 1rem calc(#{$mobilePlayerBarHeight} + 48px + 2rem) 1rem;
    max-width: 1400px;
    margin: 0 auto;
  }

  @include desktop {
    overflow-y: auto;

    .inner {
      padding: 2rem;
    }
  }
}
