@import "scss/mixins";

.root {
  --height: var(--tap-target);
  --radius: calc(var(--height) / 2);

  display: inline-flex;
  position: relative;
  overflow: hidden;
  height: var(--height);
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  padding: 0 1rem;
  outline: 0;
  border: 1px solid transparent;
  border-radius: 0.5rem;
  white-space: nowrap;
  cursor: pointer;
  transition: color 0.3s, background-color 0.3s;
  text-decoration: none;
  font: inherit;

  @include desktop() {
    padding: 0 2rem;
  }

  &:disabled {
    pointer-events: none;
    opacity: 0.6;
  }

  &.primary {
    color: #fff;
    border-color: var(--color-primary);
    background-color: var(--color-primary);

    @include shadow(low);

    &:hover,
    &:focus-visible {
      background-color: transparent;
      color: var(--color-interaction-focus);
    }
  }

  &.primary.danger {
    color: #fff;
    border-color: var(--color-danger);
    background-color: var(--color-danger);

    &:hover,
    &:focus-visible {
      background-color: transparent;
      color: var(--color-danger);
    }
  }

  &.outlined {
    color: currentColor;
    border-color: currentColor;
    background-color: transparent;

    @include hover {
      &::after {
        content: "";
        opacity: 0.15;
        background-color: currentColor;

        @include coverParent;
      }
    }
  }

  &.text {
    color: inherit;
    background-color: transparent;

    &:focus-visible,
    &:hover {
      background-color: var(--color-interaction-border);
    }
  }

  &:not(.primary).danger {
    color: var(--color-danger);
  }

  .linkIcon {
    flex-shrink: 0;
    opacity: 0.5;
  }
}
