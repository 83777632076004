@import "scss/mixins";

$size: 44px;

.root {
  display: inline-grid;
  place-items: center;
  border: none;
  background-color: transparent;
  outline: 0;
  width: $size;
  height: $size;
  user-select: none;
  padding: 0;
  color: inherit;
  border-radius: 4px;

  @include hover() {
    cursor: pointer;
  }

  &:disabled {
    opacity: 0.3;
    cursor: initial;
  }
}
