.session {
  display: flex;
  align-items: center;
  padding: 0.5rem 1rem;
  margin-bottom: 1rem;

  .icon {
    flex-shrink: 0;
    color: var(--color-emphasis-low);
  }

  .details {
    margin-left: 1rem;
    width: 100%;
  }

  > button {
    flex-shrink: 0;
  }
}

.revokeForm {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
