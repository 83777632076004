body {
  --font-weight: 400;
  --tap-target: 48px;

  // general colors
  --color-primary: #435f7a;
  --color-primary-shade: #34495e;
  --color-success: #27ae60;
  --color-danger: #e74c3c;

  // interaction element colors
  --color-interaction-background: #fbfbfb;
  --color-interaction-border: #ebebeb;
  --color-interaction-focus: #435f7a;

  // text colors
  --color-emphasis-high: #121212;
  --color-emphasis-medium: #474747;
  --color-emphasis-low: #616161;

  // background colors
  --color-elevation-low: #fff;
  --color-elevation-medium: #fff;
  --color-elevation-high: #fff;
  --body-background: var(--color-primary);

  &[data-theme='night'] {
    --font-weight: 300;

    // general colors
    --color-primary: #2b3d4f;
    --color-primary-shade: #1d2935;

    // interaction element colors
    --color-interaction-background: var(--color-primary);
    --color-interaction-border: #243342;
    --color-interaction-focus: #e2e2e2; //#61f3d5;

    // text colors
    --color-emphasis-high: #fff;
    --color-emphasis-medium: #f5f5f5;
    --color-emphasis-low: #ebebeb;

    // background colors
    --color-elevation-low: #161f27;
    --color-elevation-medium: var(--color-primary-shade);
    --color-elevation-high: #32475d;
    --body-background: var(--color-elevation-low);
  }

  margin: 0;
  font-size: 15px;
  font-family:
    "FiraSans",
    sans-serif;
  font-weight: var(--font-weight);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--color-emphasis-high);
  background-color: var(--body-background);

  &.no-scroll {
    overflow: hidden;
  }
}

* {
  box-sizing: border-box;
  scrollbar-color:
    var(--color-primary)
    transparent;

  &:focus {
    outline: 1px solid currentColor;
  }

  @supports #{'\selector(*:focus-visible)'} {
    &:focus {
      outline: 0;
    }

    &:focus-visible {
      outline: 1px solid currentColor;
    }
  }
}

@font-face {
  font-family: 'FiraSans';
  src: local('FiraSans'), url(./assets/fonts/FiraSans-Regular.ttf) format('truetype');
  font-weight: 400;
}

@font-face {
  font-family: 'FiraSans';
  src: local('FiraSans'), url(./assets/fonts/FiraSans-Light.ttf) format('truetype');
  font-weight: 300;
}
