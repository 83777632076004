.root {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: space-between;

  .dragContainer {
    display: flex;
    align-items: center;
    width: 100%;
    height: 22px;
    cursor: pointer;
    touch-action: none;

    .track {
      position: relative;
      width: 100%;
      height: 3px;
      background-color: rgba(#fff, 0.3);
      border-radius: 3px;

      .knob {
        position: absolute;
        top: 50%;
        width: 12px;
        height: 12px;
        transform: translate(-50%, -50%);
        background-color: #fff;
        border-radius: 100%;
        opacity: 0;
        transition: opacity 0.1s;
      }

      .trackValue {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        border-radius: inherit;
        background-color: #fff;
        transition: background-color 0.1s;
      }
    }
  }

  &.disabled .dragContainer {
    cursor: initial;
  }

  &:hover:not(.disabled),
  &.isDragging:not(.disabled) {
    .knob {
      opacity: 1;
    }

    .trackValue {
      background-color: var(--color-danger);
    }
  }
}
