@import "scss/mixins";

.root {
  .info {
    display: grid;
    gap: 0.5rem;
    padding: 1rem 0;
    text-align: center;

    .name {
      font-weight: bold;

      @include text-ellipsis();

      &:hover {
        text-decoration: underline;
      }
    }

    .counts {
      opacity: 0.6;
    }
  }
}
