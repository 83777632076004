$desktop-width: 800px;

@mixin desktop {
  @media (min-width: #{$desktop-width}) {
    @content;
  }
}

@mixin canHover {
  @media (hover: hover) and (pointer: fine) {
    @content;
  }
}

@mixin hover {
  @include canHover {
    &:hover {
      @content;
    }
  }
}

@mixin coverParent {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

@mixin shadow($elevation) {
  @if $elevation == low {
    box-shadow:
      0 1px 1px 0 rgba(0, 0, 0, 0.14),
      0 2px 1px -1px rgba(0, 0, 0, 0.12),
      0 1px 3px 0 rgba(0, 0, 0, 0.2);
  }

  @else if $elevation == medium {
    box-shadow:
      0 12px 17px 2px rgba(0, 0, 0, 0.14),
      0 5px 22px 4px rgba(0, 0, 0, 0.12),
      0 7px 8px -4px rgba(0, 0, 0, 0.2);
  }

  @else if $elevation == high {
    box-shadow:
      0 24px 38px 3px rgba(0, 0, 0, 0.14),
      0 9px 46px 8px rgba(0, 0, 0, 0.12),
      0 11px 15px -7px rgba(0, 0, 0, 0.2);
  }

  @else {
    @error "Unknown shadow elevation value '#{$elevation}'";
  }
}

@mixin theme($name) {
  body[data-theme='#{$name}'] & {
    @content;
  }
}

@mixin text-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@mixin active-dot {
  position: relative;

  &::after {
    content: '';
    position: absolute;
    right: 12px;
    top: 0;
    bottom: 0;
    margin: auto 0;
    background-color: currentColor;
    width: 8px;
    height: 8px;
    border-radius: 100%;
  }
}
