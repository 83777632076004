.root {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 2rem 0;

  .icon {
    width: 64px;
    height: 64px;
    border: 5px dashed #000;
    opacity: 0.2;
  }

  .text {
    margin-top: 2rem;
    text-align: center;
    white-space: nowrap;
    color: var(--color-emphasis-low);
    font-size: 1.25rem;
  }
}
