@import "scss/mixins";

.root {
  .info {
    display: grid;
    gap: 0.5rem;
    padding: 1rem 0;

    .name,
    .artist {
      display: block;

      @include text-ellipsis();
    }

    .name {
      font-weight: bold;
    }

    .artist {
      opacity: 0.6;
    }
  }
}
