.root {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: var(--color-danger);
  padding: 10vh 10vw;

  .message {
    margin-top: 2rem;
    color: var(--color-danger);
  }
}
